<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" md="4" v-for="gallery in gallerys" :key="`${gallery}`">
        <v-img
          :src="`/img/slide/${gallery}.jpg`"
          height="300"
          width="500"
        ></v-img>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-btn :to="{ name: 'Gallery' }" class="button" color="primary"
          >View More</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gallerys: 3,
    };
  },
};
</script>

import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    setup: {},
    applicants: [],
    admins: [],
    curUser: {},
    loading: false,
    login: false,
    enquiries: [],
  },
  mutations: {
    // synchronous
    setup: (state, payload) => (state.setup = payload),
    admins: (state, payload) => (state.admins = payload),
    curUser: (state, payload) => (state.curUser = payload),
    loading: (state, payload) => (state.loading = payload),
    login: (state, payload) => (state.login = payload),
    enquiries: (state, payload) => (state.enquiries = payload),
  },
  actions: {
    // asynchronous
    setLoading: ({ commit }, payload) => commit("loading", payload),
    setLogin: ({ commit }, payload) => commit("login", payload),
    setCurUser: ({ commit }, payload) => commit("curUser", payload),
    setSetup: ({ commit }, payload) => commit("setup", payload),
    setAdmins: ({ commit }, payload) => commit("admins", payload),
    setEnquiries: ({ commit }, payload) => commit("enquiries", payload),
  },
  getters: {
    getState: (state) => (field) => state[field],
    getCounts: (state) => state.counts,
    userId: (state) => state.curUser.id || "",
    getCurUser: (state) => state.curUser.fullname,
    getAdminLevel: (state) => state.curUser.admin_level,
    isAdmin: (state) =>
      state.curUser.username ?
      state.curUser.username.toLowerCase().startsWith("admin") :
      false,
  },
  modules: {},
});
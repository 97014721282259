<template>
  <v-app>
    <app-nav />
    <v-main>
      <router-view />
    </v-main>
    <foot-page />
  </v-app>
</template>

<script>
import AppNav from "@/components/AppNav.vue";
import FootPage from "@/components/FootPage.vue";

export default {
  name: "App",
  components: { AppNav, FootPage },
};
</script>

<style>
* {
  font-family: "Poppins", sans-serif;
}

.cursor-pointer {
  cursor: pointer;
}

.button {
  color: #4c5ad4;
  font-size: 12px;
  padding: 15px 25px;
  background: linear-gradient(to right, transparent 50%, #070d68 50%);
  background-size: 200%;
  background-position: left;
  transition: background-position 0.5s;
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
}

.button:hover {
  background-position: right;
  color: rgb(0, 2, 121);
}

.img:hover {
  opacity: 0.7;
}


</style>

<template>
  <div>
    <carousel></carousel>
    <v-container class="mt-5">
      <h1 class="mt-10 secondary--text text-center">
        <v-icon color="primary">mdi-camera-iris</v-icon>SERVICES<v-icon
          color="primary"
          >mdi-camera-iris</v-icon
        >
      </h1>

      <router-link :to="{ name: 'Bookstore' }" class="text-decoration-none">
        <v-row class="mt-6" justify="center">
          <v-col cols="12" md="6">
            <v-img
              transition="scroll-x-transition"
              class="img"
              src="img/bookgift.jpg"
              height="300"
              width="500"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              src="img/bookstore.png"
              height="100"
              width="100"
              transition="scroll-y-transition"
              class="mx-auto"
            ></v-img>
            <p class="secondary--text">
              Christore Books is Ekiti Favourite and award winning bookstore
              with variety of different genres available. From international and
              local authors, motivational best sellers to fiction and
              non-fictions, books are available at our different locations
              across the state.
            </p>
          </v-col>
        </v-row></router-link
      >

      <router-link :to="{ name: 'Hall' }" class="text-decoration-none">
        <v-row class="mt-10">
          <v-col order="1" order-sm="1">
            <v-img
              transition="scroll-y-transition"
              src="img/hall.png"
              height="100"
              width="100"
              class="mx-auto"
            ></v-img>
            <p class="secondary--text">
              A multipurpose hall for your events, conferences and seminars,
              HEPHZIBAH HALL offers you a memorable experience for you. We have
              the perfect space for hosting your celebrations, conferences,
              meetings and corporate events. <br />
              Our venue will make your special moment even more memorable.
            </p>
          </v-col>
          <v-col order-sm="2">
            <v-img
              transition="scroll-x-transition"
              class="img"
              src="img/Hall.jpg"
              height="300"
              width="500"
            ></v-img>
          </v-col> </v-row
      ></router-link>

      <router-link
        :to="{ name: 'CoworkingOffices' }"
        class="text-decoration-none"
      >
        <v-row class="mt-10">
          <v-col cols="12" md="6">
            <v-img
              transition="scroll-x-transition"
              class="img"
              src="img/hub.jpeg"
              height="300"
              width="500"
            ></v-img>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              transition="scroll-y-transition"
              src="img/hub.png"
              height="100"
              width="100"
              class="mx-auto"
            ></v-img>
            <p class="secondary--text">
              CHRISTORE OFFICE HUB is a Co-working Offices based in Ado-Ekiti,
              Ekiti State and focuses on corporate experiences, inspiring shared
              office spaces, convenient virtual offices and well equipped
              private offices. The hub offers a dynamic community for people and
              business to grow together.
            </p>
          </v-col>
        </v-row></router-link
      >
    </v-container>

    <v-container class="mt-7">
      <h1 class="mt-10 secondary--text text-center">
        <v-icon color="primary">mdi-camera</v-icon>GALLERY<v-icon
          color="primary"
          >mdi-camera</v-icon
        >
      </h1>
      <gallery-page class="mt-5" />
    </v-container>

    <v-container fluid class="pb-0">
      <v-col cols="12" class="text-center mt-10 grey lighten-5">
        <h1 class="mt-10 secondary--text">
          <v-icon color="primary">mdi-camera-iris</v-icon>TESTIMONIALS<v-icon
            color="primary"
            >mdi-camera-iris</v-icon
          >
        </h1>
        <testimo-nial />
      </v-col>
    </v-container>
  </div>
</template>

<script>
import Carousel from "../components/Carousel.vue";
import GalleryPage from "../components/GalleryPage.vue";
import TestimoNial from "../components/TestimoNial.vue";
export default {
  name: "Home",

  components: { Carousel, TestimoNial, GalleryPage },
};
</script>

import Vue from "vue";
import VueRouter from "vue-router";
import auth from "@/auth";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "About" */ "@/views/About.vue"),
  },
  {
    path: "/contact",
    name: "Contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "Contact" */ "@/views/Contact.vue"),
  },
  {
    path: "/event-and-news",
    name: "Event",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "Event" */ "@/views/EventsAndNews.vue"),
  },
  {
    path: "/gallery",
    name: "Gallery",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "Gallery" */ "@/views/Gallery.vue"),
  },
  {
    path: "/bookstore",
    name: "Bookstore",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "Bookstore" */ "@/views/BookStore.vue"),
  },
  {
    path: "/hall",
    name: "Hall",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "Hall" */ "@/views/Hall.vue"),
  },
  {
    path: "/coworking-offices",
    name: "CoworkingOffices",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "CoworkingOffices" */ "@/views/CoworkingOffices.vue"),
  },
  {
    path: "/join-bookclub",
    name: "JoinBookclub",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "JoinBookclub" */ "@/views/JoinBookclub.vue"),
  },
  {
    path: "/book-order",
    name: "BookOrder",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "BookOrder" */ "@/views/BookOrder.vue"),
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import ( /* webpackChunkName: "Dashboard" */ "@/views/Admin/Dashboard.vue"),
    meta: { requiresAuth: true },
    children: [{
        path: "/alladmin",
        name: "Alladmin",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import ( /* webpackChunkName: "Alladmin" */ "@/views/Admin/Alladmin.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/nadmin",
        name: "Nadmin",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import ( /* webpackChunkName: "Nadmin" */ "@/views/Admin/Nadmin.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/members",
        name: "Members",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import ( /* webpackChunkName: "Members" */ "@/views/Admin/Members.vue"),
        meta: { requiresAuth: true },
      },
      {
        path: "/Enquiries",
        name: "Enquiries",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import ( /* webpackChunkName: "Enquiries" */ "@/views/Admin/Enquiries.vue"),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: "/admin-login",
    name: "AdminLogin",
    component: () =>
      import ( /* webpackChunkName: "AdminLogin" */ "@/views/Admin/Login.vue"),
  },
  {
    path: "/404",
    alias: "*",
    name: "NotFound",
    component: () =>
      import ( /* webpackChunkName: "NotFound" */ "@/views/NotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.login) {
      next({ name: "AdminLogin", query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
<template>
  <div>
    <v-app-bar class="navbar" color="secondary" dark outlined app clipped-right>
        <router-link
          class="white--text font-weight-regular routerlink"
          :to="{ name: links[0].name }"
        >
          <v-img
            height="50"
            class="logo"
            :src="`img/${logo}`"
          ></v-img></router-link
      >
      <v-spacer></v-spacer>
      <v-card-subtitle
        v-for="(link, idx) in links"
        :key="idx"
        v-show="!mobile"
        text
        rounded
        class="font-weight-bold"
      >
        <router-link class="primary--text routerlink" :to="{ name: link.name }">
          <v-icon class="mr-1" color="primary">{{ link.icon }}</v-icon
          >{{ link.label }}</router-link
        >
      </v-card-subtitle>

      <v-menu top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small  class="button-size" color="primary" dark v-bind="attrs" v-on="on">
            Services <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            color="primary"
            link
            :to="item.link"
            v-for="(item, index) in menuItems"
            :key="index"
          >
            <v-list-item-title class="secondary--text">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-app-bar-nav-icon
        color="primary"
        @click="drawer = !drawer"
        v-show="mobile"
      ></v-app-bar-nav-icon>
    </v-app-bar>

    <!-- SIDE NAVIGATION -->
    <v-navigation-drawer
      :app="mobile"
      color="secondary"
      v-model="drawer"
      v-show="mobile"
      dark
      clipped
      right
    >
      <v-row justify="end" class="pa-5">
        <v-icon @click="drawer = false">mdi-close</v-icon>
      </v-row>

      <SideNav :hideDrawer="hideDrawer" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import SideNav from "@/components/SideNav.vue";
export default {
  name: "AppNav",
  components: { SideNav },
  data: () => ({
    links: [
      {
        label: "HOME",
        name: "Home",
        icon: "mdi-home",
      },
      {
        label: "ABOUT",
        name: "About",
        icon: "mdi-wallet-travel",
      },
      {
        label: "CONTACT",
        name: "Contact",
        icon: "mdi-human-greeting-proximity",
      },
      {
        label: "EVENTS & NEWS",
        name: "Event",
        icon: "mdi-newspaper",
      },
    ],
    menuItems: [
      { title: "Bookstore", link: { name: "Bookstore" } },
      { title: "Hall", link: { name: "Hall" } },
      { title: "Co-Working Offices", link: { name: "CoworkingOffices" } },
    ],
    drawer: false,
    offset: true,
  }),
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    }, 

    logo() {
      switch (this.$route.name) {
        case "Bookstore":
          return "bookstore.png";
        case "Hall":
          return "hall.png";
        case "CoworkingOffices":
          return "hub.png";
        default:
          return "christore.png";
      }
    },
  },
  watch: {
    mobile(newValue) {
      if (newValue) {
        this.drawer = false;
      }
    },
  },
  methods: {
    hideDrawer() {
      if (this.mobile) {
        this.drawer = false;
      }
    },
  },
};
</script>

<style scoped>
.routerlink {
  text-decoration: none;
}

.logo {
  width: 5em;
  display: block;
  height: 5em;
}
</style>

<template>
  <v-carousel cycle height="300" hide-delimiter-background show-arrows-on-hover>
    <v-carousel-item v-for="(slide, i) in slides" :key="i" class="mt-5">
      <v-card
        color="rgb(0, 0, 0, 0.5)"
        height="200"
        width="500"
        class="mx-auto"
        outlined
        elevation="2"
      >
        <v-row class="mt-7" justify="center">
          <v-rating
            class="text-center"
            color="warning"
            readonly
            small
            :value="5"
          >
          </v-rating>
          <span class="grey--text text-caption mt-2 ml-3">
            {{ say }}
          </span>
        </v-row>
        <v-card-text class="white--text">{{ slide.text }}</v-card-text>
        <v-card-title class="white--text">{{ slide.title }}</v-card-title>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  data() {
    return {
      say: "- a year ago",

      slides: [
        {
          text: "I love the unique gift items I saw there and your customer relations is second to none. Cheers",
          title: "- omolola a",
        },
        {
          text: "Great customer service ",
          title: "- Matthew P",
        },
        {
          text: "Its the best bookstore in town.",
          title: "- Marcus P",
        },
      ],
    };
  },
};
</script>

<template>
  <div>
    <v-carousel
      cycle
      show-arrows-on-hover
      progress
      progress-color="primary"
      interval="5000"
      height="100%"
    >
      <v-carousel-item
        v-for="(item, idx) in carouselItems"
        :key="idx"
        :src="`img/${item.image}`"
      >
        <v-row class="fill-height" align="center" justify="center">
          <v-col class="display-1" align="center" justify="center"
            ><div>
              <v-card class="slide" rounded="xl" color="rgb(0, 0, 0, 1.0)">
                <v-card-text class="primary--text slide-text ">{{
                  item.description
                }}</v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "Carousel",
  data: () => ({
    carouselItems: [
      {
        image: "homecarousel2.jpg",
        description: "BOOKSTORE",
      },
      {
        image: "homecarousel1.jpg",
        description: "HEPHZIBAL HALL ",
      },
      {
        image: "homecarousel3.jpg",
        description: "Co-WORKING OFFICES",
      },
    ],
  }),
};
</script>

<style>

@media screen and (max-width: 600px) {
.slide{
width: 200px;
}

.slide-text{
font-size: 20px;
font-weight: 00;
}

}
@media screen and (min-width: 600px) {
.slide{
width: 550px;
}

.slide-text{
font-size: 40px;
font-weight: 700;
padding: 30px;
}

}

</style>


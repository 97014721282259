<template>
  <div>
    <v-list dense>
      <v-list-item v-for="(link, idx) in links" :key="idx" @click="hideDrawer">
        <v-btn
          color="primary"
          class="font-weight-black"
          text
          :to="{ name: link.name }"
          ><v-list-item-icon>
            <v-icon color="primary">{{ link.icon }}</v-icon> </v-list-item-icon
          >{{ link.label }}</v-btn
        >
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  data: () => ({
    links: [
      {
        label: "Home",
        name: "Home",
        icon: "mdi-home",
      },
      {
        label: "About",
        name: "About",
        icon: "mdi-wallet-travel",
      },
      {
        label: "Gallery",
        name: "Gallery",
        icon: "mdi-file-image",
      },
      {
        label: "Events & News",
        name: "Event",
        icon: "mdi-newspaper",
      },
      {
        label: "Contact",
        name: "Contact",
        icon: "mdi-human-greeting-proximity",
      },
    ],
  }),
  props: {
    hideDrawer: Function,
  },
};
</script>

<template>
  <v-footer color="primary" padless class="text-center">
    <v-card color="secondary" class="mx-auto" width="100%">
      <v-row justify="end">
        <v-card class="mt-n5 mr-16">
          <v-img src="img/christore.png" width="100" height="50"></v-img>
        </v-card>
      </v-row>

      <v-row class="mx-auto pt-5">
        <v-col cols="12" sm="6" md="3">
          <h4 class="mb-3 white--text">CHRISTORE BUILDING</h4>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="white">mdi-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text mb-3"
                >Corporate Headquarters:</v-list-item-title
              >
              <v-list-item-subtitle class="primary--text"
                >68b, EKSUTH ROAD, <br />
                Opp CRUNCHIES EATERY. <br />
                ADO EKITI.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="white">mdi-phone-in-talk</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="primary--text mb-3"
                >Call us:</v-list-item-title
              >
              <v-list-item-subtitle class="primary--text">
                +2348134072342, <br />
                +2347039301287
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <h4 class="mb-3 white--text">COMPANY INFORMATION</h4>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <router-link
                  class="text-decoration-none font-weight-regular primary--text"
                  :to="{ name: 'About' }"
                  >About Us</router-link
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <router-link
                  class="text-decoration-none font-weight-regular primary--text"
                  :to="{ name: 'Contact' }"
                  >Contact Us</router-link
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <router-link
                  class="text-decoration-none font-weight-regular primary--text"
                  :to="{ name: 'Event' }"
                  >Event & News</router-link
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <h4 class="mb-3 white--text">SERVICES</h4>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <router-link
                  class="text-decoration-none font-weight-regular primary--text"
                  :to="{ name: 'Bookstore' }"
                  >Bookstore</router-link
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <router-link
                  class="text-decoration-none font-weight-regular primary--text"
                  :to="{ name: 'Hall' }"
                  >Hall</router-link
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-subtitle>
                <router-link
                  class="text-decoration-none font-weight-regular primary--text"
                  :to="{ name: 'CoworkingOffices' }"
                  >Co-Working Offices</router-link
                >
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <h4 class="mb-2 white--text">FOLLOW US</h4>
          <v-btn
            v-for="(item, idx) in icons"
            :key="idx"
            color="white"
            text
            rounded
            class="my-2"
            :href="item.link"
            target="blank"
          >
            <v-icon color="primary"> {{ item.icon }} </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-divider class="primary--text mt-6"></v-divider>
      </v-row>

      <v-divider class="white"></v-divider>
      <div class="text-end ma-2">
        <v-btn
          v-if="!login"
          text
          :to="{ name: 'AdminLogin' }"
          shaped
          color="info"
          x-small
          >Staff</v-btn
        >
        <v-btn
          v-if="login"
          text
          :to="{ name: 'Dashboard' }"
          shaped
          color="primary white--text"
          small
          >Dashboard</v-btn
        >
        <v-btn v-if="login" @click="logout" shaped color="primary" small
          >Logout</v-btn
        >
      </div>
      <v-card-text class="white--text text-center">
        Copyright &copy; {{ new Date().getFullYear() }}
        <strong>Christore&trade; Services</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "FootPage",

  data: () => ({
    icons: [
      {
        icon: "mdi-facebook",
        link: "https://web.facebook.com/ChristoreHub-110555760509184/",
      },
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/explore/locations/193017457718104/christore-booksopp-dental-center/",
      },
      {
        icon: "mdi-linkedin",
        link: "https://www.linkedin.com/company/christore-hub",
      },
      {
        icon: "mdi-twitter",
        link: "https://www.twitter.com/christorehub",
      },
    ],
  }),

  computed: mapState(["login"]),

  methods: {
    ...mapActions(["setLogin"]),
    logout() {
      this.setLogin(false);
      this.$router.push({ name: "AdminLogin" });
    },
  },
};
</script>
